﻿<template>
  <v-form ref="form">
    <v-card class="ma-4" variant="outlined">
      <v-card-title class="HeaderTitle">
        <v-toolbar color="primary" density="compact" flat>
          <v-toolbar-title class="textcolor">
            Ihre Kundendaten
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-col v-if="loading == false">
          <v-row class="ma-1" v-if="Vorhanden('Kundennummer') == true">
            <v-text-field
              v-model="$store.state.Kundennummer"
              :label="$store.state.KundennummerDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[
                rules.required('Kundenummer', $store.state.Kundennummer),
              ]"
            >
            </v-text-field>
          </v-row>

          <v-row class="mr-2 ml-2 mt-4 mb-2">
            <v-combobox
              v-if="$store.state.VerwaltungEinschalten == false"
              v-model="FuerPerson"
              :label="$store.state.FuerPersonDisplayName"
              color="primary"
              :items="FuerPersonArray"
              variant="outlined"
              density="compact"
              :rules="[rules.required2]"
            >
            </v-combobox>
          </v-row>

          <v-row
            class="ma-1"
            v-if="FuerPerson == 'Firma' && Vorhanden('Firma') == true"
          >
            <v-text-field
              v-model="$store.state.Firma"
              :label="$store.state.FirmaDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Firma', $store.state.Firma)]"
            >
            </v-text-field>
          </v-row>

          <div
            v-if="FuerPerson == 'Privatperson' && Vorhanden('Anrede') == true"
          >
            <v-row class="ma-1">
              <v-combobox
                v-model="$store.state.Anrede"
                :items="AnredeItems"
                :label="$store.state.AnredeDisplayName"
                color="primary"
                variant="outlined"
                density="compact"
                :readonly="NurLesen"
                :rules="[rules.required('Anrede', $store.state.Anrede)]"
              ></v-combobox>
            </v-row>

            <v-row class="ma-1">
              <v-text-field
                v-if="Vorhanden('Nachname') == true"
                v-model="$store.state.Nachname"
                style="margin-right: 0.5em"
                :label="$store.state.NachnameDisplayName"
                color="primary"
                variant="outlined"
                density="compact"
                :readonly="NurLesen"
                :rules="[rules.required('Nachname', $store.state.Nachname)]"
              >
              </v-text-field>

              <v-text-field
                v-if="Vorhanden('Vorname') == true"
                v-model="$store.state.Vorname"
                :label="$store.state.VornameDisplayName"
                color="primary"
                variant="outlined"
                density="compact"
                :readonly="NurLesen"
                :rules="[rules.required('Vorname', $store.state.Vorname)]"
              >
              </v-text-field>
            </v-row>
          </div>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('Strasse') == true"
              v-model="$store.state.Strasse"
              style="margin-right: 0.5em"
              :label="$store.state.StrasseDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Strasse', $store.state.Strasse)]"
            >
            </v-text-field>

            <v-text-field
              v-if="Vorhanden('Hausnummer') == true"
              v-model="$store.state.Hausnummer"
              style="margin-right: 0.5em"
              :label="$store.state.HausnummerDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Hausnummer', $store.state.Hausnummer)]"
            >
            </v-text-field>

            <v-text-field
              v-if="Vorhanden('HausNrErg') == true"
              v-model="$store.state.HausNrErg"
              :label="$store.state.HausNrErgDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('HausNrErg', $store.state.HausNrErg)]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('Plz') == true"
              style="margin-right: 0.5em"
              v-model="$store.state.Plz"
              :label="$store.state.PlzDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Plz', $store.state.Plz)]"
            >
            </v-text-field>

            <v-text-field
              v-if="Vorhanden('Ort') == true"
              v-model="$store.state.Ort"
              :label="$store.state.OrtDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Ort', $store.state.Ort)]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('Telefon') == true"
              v-model="$store.state.Telefon"
              :label="$store.state.TelefonDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Telefon', $store.state.Telefon)]"
            >
            </v-text-field>

            <v-text-field
              class="ml-1"
              v-if="Vorhanden('Telefon2') == true"
              v-model="$store.state.Telefon2"
              :label="$store.state.Telefon2DisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Telefon2', $store.state.Telefon2)]"
            >
            </v-text-field>

            <v-text-field
              class="ml-1"
              v-if="Vorhanden('Mobil') == true"
              v-model="$store.state.Mobil"
              :label="$store.state.MobilDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Mobil', $store.state.Mobil)]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('EMail') == true"
              v-model="$store.state.EMail"
              :label="$store.state.EMailDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[
                rules.required('EMail', $store.state.EMail),
                rules.EMail,
              ]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('Land') == true"
              v-model="$store.state.Land"
              :label="$store.state.LandDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :readonly="NurLesen"
              :rules="[rules.required('Land', $store.state.Land)]"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-row class="ma-2" v-if="loading == true">
          <Lodingscreen />
        </v-row>
        <v-row
          v-if="Vorhanden('SonstigeButtons') == true"
          class="mr-2 ml-3 mb-1"
        >
          <sub> <h1>*</h1></sub>
          <h4>Pflichtfelder</h4></v-row
        >
      </v-card-text>
    </v-card>
  </v-form>

  <v-dialog v-model="Fehler"> <Errors /></v-dialog>
</template>

<script setup>
import Lodingscreen from "@/components/main/loadingscreen.vue";
import Errors from "@/components/main/Errors.vue";
import dbhelper from "@/plugins/dbHelper";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

defineExpose({
  checkvalidate,
  resetFelder,
});
const store = useStore();

var form = ref(null);
var loading = ref(false);
var Fehler = ref(false);

var NurLesen = ref(false);
var FuerPerson = ref("");
var FuerPersonArray = ref(["Firma", "Privatperson"]);

onMounted(async () => {
  loading.value = true;

  if (store.state.VerwaltungEinschalten == true) {
    NurLesen.value = true;
    var url = "GETKontakt";
    var response = await dbhelper.dbload(url);
  
    response.Data = JSON.parse(response.Data);

    if (response.result.erfolgJN == "J") {
      store.state.Kundennummer = response.Data.KundenNr;

      if (response.Data.Firma.length == 0) FuerPerson.value = "Privatperson";
      else FuerPerson.value = "Firma";

      store.state.Firma = response.Data.Firma;
      store.state.Anrede = response.Data.Anrede;
      store.state.Nachname = response.Data.Nachname;
      store.state.Vorname = response.Data.Vorname;
      store.state.Strasse = response.Data.Strasse;
      store.state.Hausnummer = response.Data.HausNr;
      store.state.HausNrErg = response.Data.HausNrErg;
      store.state.Plz = response.Data.Plz;
      store.state.Ort = response.Data.Ort;
      store.state.Telefon = response.Data.Telefon1;
      store.state.Telefon2 = response.Data.Telefon2;
      store.state.Mobil = response.Data.Mobil;
      store.state.EMail = response.Data.EMail;
      store.state.Land = response.Data.Land;
    } else {
      store.state.FehlerCode = response.Data.FehlerCode;
      Fehler.value = true;
    }
  }

  loading.value = false;
});

var AnredeItems = ref(["Herr", "Frau"]);
var rules = ref({
  required(Feld, value) {
    var istPflichtfeld = false;
    store.state.VertragsPflichtfelder.forEach((element) => {
      if (element == Feld) istPflichtfeld = true;
    });
    if (istPflichtfeld == true) return !!value || "Pflichtfeld";
    else return true;
  },
  required2: (value) => !!value || "Pflichtfeld",
  EMail: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Ungültige E-Mail";
  },
});

// Prüft ob es das Feld angezeigt werden soll.
// Welche Felder anezeigt werden sollen werden in der INI bestimmmt.
function Vorhanden(Feld) {
  var FeldNichtvorhandewn = false;
  store.state.FelderNichtAnzeigen.forEach((element) => {
    if (element == Feld) FeldNichtvorhandewn = true;
  });
  if (FeldNichtvorhandewn == true) return false;
  else return true;
}

async function checkvalidate() {
  let test = await form.value.validate();
  if (test.valid == true) return true;
  return false;
}

function resetFelder() {
  if (store.state.VerwaltungEinschalten == false) form.value.reset();
}
</script>

<style>
.textcolor {
  color: white;
}
</style>